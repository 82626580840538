import { ADD_QUERY, BEFORE_QUERY } from '../../../redux/types'
const initialState = {
    addQueryRes: {},

    //check POST API
    authQuery: false,

}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_QUERY:
            return {
                ...state,
                addQueryRes: action.payload,
                authQuery: true,
            }

        case BEFORE_QUERY:
            return {
                ...state,
                authQuery: false,
            }
        default:
            return {
                ...state
            }
    }
}