import { combineReducers } from 'redux';
import faqReducer from '../components/faqComponent/reducer/faq.reducer';
import authReducer from '../components/auth/reducers/auth.reducer';
import contactusReducer from '../components/contactus/reducer/contactus.reducer';
import errorReducer from '../redux/sharedReducer/error.reducer';
import siteSettingsReducer from './sharedReducer/siteSettings.reducer';
import headerReducer from '../shared/components/header/reducer/header.reducer';
import profileReducer from '../components/userProfile/reducer/userProfile.reducer';
import homeReducer from '../components/home/reducer/home.reducer';
import quizReducer from '../components/quiz/reducers/quiz.reducer';
import quizResult from '../components/quizResults/reducers/quizResult.reducer';
import dealsReducer from '../components/deals/reducers/deals.reducer';
import campaignsReducer from '../components/campaigns/reducers/campaigns.reducer';
import contestReducer from '../components/contest/reducers/contest.reducer';
import dashboardReducer from '../components/dashboard/reducers/dashboard.reducer';
import topMathletesReducer from '../components/topMathletes/reducers/topMathletes.reducer';
import topPerformersReducer from '../components/home/reducer/home.reducer';
import howToReducer from '../components/howToPlay/reducer/howTo.reducer';
import dealRedeemHistoryReducer from '../components/dealRedeemHistory/reducers/dealRedeemHistory.reducer';

export default combineReducers({
    support: faqReducer,
    auth: authReducer,
    contactus: contactusReducer,
    errors: errorReducer,
    siteSettings: siteSettingsReducer,
    header: headerReducer,
    profile: profileReducer,
    home: homeReducer,
    quiz: quizReducer,
    deals: dealsReducer,
    campaigns: campaignsReducer,
    quizResult: quizResult,
    contest: contestReducer,
    dashboard: dashboardReducer,
    topMathletes: topMathletesReducer,
    topPerformers: topPerformersReducer,
    appUrl: howToReducer,
    dealRedeemHistory: dealRedeemHistoryReducer
})