import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import $ from 'jquery';

class FooterComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showImageDivider: true,
            settingRes: {},
            footerLinks: true
        };
    }

    componentDidMount() {
        let path = window.location.pathname;
        let mobile = localStorage.getItem('mobile-app')
        if (mobile === 'true') {
            this.setState({
                footerLinks: false
            })
        }
        if (path === "/") {
            this.setState({ showImageDivider: false })
        }
    }

    handleScroll = () => {
        const $ = window.$;

        const headerHeight = $('#header').innerHeight();
        if (window.pageYOffset >= headerHeight) {
            $('.project-by').css('display', 'none');
        }
        else if (window.pageYOffset < headerHeight) {
            $('.project-by').css('display', 'block');
        }
    };

    goToUrl(url) {
        window.open(url, "_system");
    }

    notifyWarning = (msg, toastId) => {
        toast.warning(msg, {
            toastId
        });
    }

    navigateToLink(e) {
        let targetUrl = $(e.target).attr("to");
        if(!targetUrl) {
            let elementWithUrl = $(e.target).parents('[to]').first();
            targetUrl = $(elementWithUrl).attr("to");
        }
        localStorage.setItem('quit-target-url', targetUrl);
        if(this.props.location.pathname.indexOf("/quiz/") > -1) {
            window.jQuery("#exitQuizModal").modal('show');
        } else {
            console.log("else target url");
            if($(e.target).attr("reload")) {
                window.location.href = targetUrl;
            }
            else {
                this.props.history.push(targetUrl);
            }
        }
    }

    render() {
        let { data } = this.props.settings.getSettingsRes;

        return (
            <React.Fragment>
                <div className="footerHolder">
                    {
                        this.state.showImageDivider ?
                            <div className="footer-top">
                                <img className="img-fluid" src='https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581059076/mathlete/assets/img/svg/how-work-bottom_wikmqg.svg' alt="" />
                            </div>
                            :
                            ""
                    }
                    <footer>
                        <div className="container">
                            <div className="footer-links">
                                <ul>
                                    <li><span className="cur-poi" to="/" onClick={(e)=>this.navigateToLink(e)}>Home</span></li>
                                    <li><span className="cur-poi" to="/top-mathletes" onClick={(e)=>this.navigateToLink(e)}>Top Mathletes</span></li>
                                    <li><span className="cur-poi" to="/faqs" onClick={(e)=>this.navigateToLink(e)}>FAQs</span></li>
                                    <li><span className="cur-poi" to="/contact-us" onClick={(e)=>this.navigateToLink(e)}>Contact Us</span></li>
                                    <li><span className="cur-poi" to="/privacy-policy" onClick={(e)=>this.navigateToLink(e)}>Privacy Policy</span></li>
                                    <li><span className="cur-poi" to="/terms-of-service" onClick={(e)=>this.navigateToLink(e)}>Terms of Services</span></li>
                                </ul>
                            </div>
                            <div className="footer-bottom-links">
                                <div className="row">
                                    <div className="col-md-4 buttons-col">
                                        {
                                            this.state.footerLinks ?
                                                <React.Fragment>
                                                    <div className="m-icon-holder">
                                                        <img src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581058958/mathlete/assets/img/m-icon-img_dnnxzf.png" alt="Download McDonald’s App" />
                                                    </div>
                                                    <div className="download-app">
                                                        <h4 className="text-white">Download McDonald’s App</h4>
                                                        <div className="buttons">
                                                            {
                                                                (data && data.mcIosUrl) ?
                                                                    <span className="cur-poi" onClick={() => this.goToUrl(data.mcIosUrl)}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/appstore_n0dygp.png" alt="Download McDonald’s App" /></span>
                                                                    :
                                                                    <span className="cur-poi" onClick={() => this.notifyWarning('McDonald\'s iOS app will be available soon', 'iOS-mc-toast')}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/appstore_n0dygp.png" alt="Download McDonald’s App" /></span>
                                                            }

                                                            {
                                                                (data && data.mcAndroidUrl) ?
                                                                    <span className="cur-poi" onClick={() => this.goToUrl(data.mcAndroidUrl)}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/playstore_jiyzze.png" alt="Download McDonald’s App" /></span> :
                                                                    <span className="cur-poi" onClick={() => this.notifyWarning('McDonald\'s android app will be available soon', 'android-mc-toast')}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/playstore_jiyzze.png" alt="Download McDonald’s App" /></span>
                                                            }

                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                ""
                                        }
                                    </div>
                                    <div className="col-md-4">
                                        <div className="social-links">
                                            <ul>
                                                <li>
                                                    {
                                                        (data && data.fbLink) ?
                                                            <span className="cur-poi" onClick={() => this.goToUrl(data.fbLink)}> <span className="fab fa-facebook-f" /></span>
                                                            :
                                                            <span className="cur-poi" onClick={() => this.notifyWarning('Note! Facebook link will be available soon', 'fb-toast')}> <span className="fab fa-facebook-f" /></span>
                                                    }
                                                </li>
                                                <li>
                                                    {
                                                        (data && data.twitterLink) ?
                                                            <span className="cur-poi" onClick={() => this.goToUrl(data.twitterLink)}> <span className="fab fa-twitter" /></span>
                                                            :
                                                            <span className="cur-poi" onClick={() => this.notifyWarning('Note! Twitter link will be available soon', 'tw-toast')}> <span className="fab fa-twitter" /></span>
                                                    }
                                                </li>
                                                <li>
                                                    {
                                                        (data && data.linkedInLink) ?
                                                            <span className="cur-poi" onClick={() => this.goToUrl(data.linkedInLink)}> <span className="fab fa-instagram" /></span>
                                                            :
                                                            <span className="cur-poi" onClick={() => this.notifyWarning('Note! Instagram link will be available soon', 'tw-toast')}> <span className="fab fa-instagram" /></span>
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-md-4 text-right buttons-col">
                                        {
                                            this.state.footerLinks ?
                                                <React.Fragment>
                                                    <div className="math-icon-holder">
                                                        <img src='https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581058959/mathlete/assets/img/math-icon-img_c5ttlc.png' alt="Download Mathlete App" />
                                                    </div>
                                                    <div className="download-app">
                                                        <h4 className="text-white">Download Mathlete App</h4>
                                                        <div className="buttons">
                                                            {
                                                                (data && data.iosUrl) ?
                                                                    <span className="cur-poi" onClick={() => this.goToUrl(data.iosUrl)}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/appstore_n0dygp.png"  alt="Download Mathlete App"/></span>
                                                                    :
                                                                    <span className="cur-poi" onClick={() => this.notifyWarning('Mathlete\'s iOS app will be available soon', 'iOS-toast')}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/appstore_n0dygp.png"  alt="Download Mathlete App"/></span>
                                                            }

                                                            {
                                                                (data && data.androidUrl) ?
                                                                    <span className="cur-poi" onClick={() => this.goToUrl(data.androidUrl)}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/playstore_jiyzze.png"  alt="Download Mathlete App"/></span> :
                                                                    <span className="cur-poi" onClick={() => this.notifyWarning('Mathlete\'s android app will be available soon', 'android-toast')}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/playstore_jiyzze.png"  alt="Download Mathlete App" /></span>
                                                            }
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                ""
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="copyrights">
                                <p> <span className="cur-poi" onClick={() => this.goToUrl("https://www.arhamsoft.com")}>Mathlete - A Project of ArhamSoft</span> © All Rights Reserved </p>
                            </div>
                        </div>
                        <div className="left-img">
                            <span className="cur-poi" onClick={() => this.goToUrl("https://mcdonalds.com.pk")}>
                                <img src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581058958/mathlete/assets/img/mc-icon-footer_iwjxqg.png" className="img-fluid" alt="" />
                            </span>
                        </div>
                        <div className="right-img">
                            <span className="cur-poi" onClick={() => this.goToUrl("https://www.arhamsoft.com")}>
                                <img src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581058964/mathlete/assets/img/as-icon-footer_vw8422.png" className="img-fluid" alt="" />
                            </span>
                        </div>
                    </footer>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    settings: state.siteSettings,
});

export default connect(
    mapStateToProps,
    null,
)(withRouter(FooterComponent));