
import { CREATE_CONTEST_CODE, CHECK_PHASE, BEFORE_CONTEST_CODE, CODE_POPUP_SEEN} from '../../../redux/types';
const initialState = {
    createCodeRes: {},
    checkPhaseRes: {},
    codePopupSeenRes: {},
    createCodeAuth: false,
    checkPhaseAuth: false,
    codePopupSeenAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CREATE_CONTEST_CODE:
            return {
                ...state,
                createCodeRes: action.payload,
                createCodeAuth: true,
            }
        case CHECK_PHASE:
            return {
                ...state,
                checkPhaseRes: action.payload,
                checkPhaseAuth: true,
            }
        case CODE_POPUP_SEEN:
            return {
                ...state,
                codePopupSeenRes: action.payload,
                codePopupSeenAuth: true,
            }
        case BEFORE_CONTEST_CODE:
            return {
                ...state,
                createCodeAuth: false,
                checkPhaseAuth: false,
                codePopupSeenAuth: false
            }    
        default:
            return {
                ...state
            }
    }
}