import { LOGIN_ACTION, API_CALL_CHECK, RESET_PASSWORD, REGISTER_USER, BEFORE_LOGIN, RECOVERY_EMAIL, CLEAR_SESSION, BEFORE_CLEAR_SESSION, CREATE_MATHLETE_ACCOUNT} from '../../../redux/types'
const initialState = {
    name: "test",
    loginUserRes: {},
    registerUserRes: {},
    resetPasswordRes: {},
    recoveryEmailRes: {},
    authenticate: false,
    callApiState: false,
    recoveryEmail: false,
    authResetPassword: false,

    clearSessionRes: {},
    clearSessionAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_ACTION:
            return {
                ...state,
                loginUserRes: action.payload,
                authenticate: true,
            }
        case REGISTER_USER:
            return {
                ...state,
                registerUserRes: action.payload,
                authenticate: true,
            }
        case CREATE_MATHLETE_ACCOUNT:
            return {
                ...state,
                createMathleteAccountAuth: true,
                createMathleteAccountRes: action.payload,
            }
        case RESET_PASSWORD:
            return {
                ...state,
                resetPasswordRes: action.payload,
                authResetPassword: true
            }
        case API_CALL_CHECK:
            return {
                ...state,
                myData: action.payload,
                callApiState: false,
            }
        case RECOVERY_EMAIL:
            return {
                ...state,
                recoveryEmailRes: action.payload,
                recoveryEmail: true,
            }
        case BEFORE_LOGIN:
            return {
                ...state,
                authenticate: false,
                recoveryEmail: false,
                authResetPassword: false,
                createMathleteAccountAuth: false
            }
        case CLEAR_SESSION:
            return {
                ...state,
                clearSessionRes: action.payload,
                clearSessionAuth: true
            }
        case BEFORE_CLEAR_SESSION:
            return {
                ...state,
                clearSessionAuth: false
            }
        default:
            return {
                callApiState: true,
            }
    }
}