import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class TopSecSubMenuComponent extends Component {
    render() {
        return (
            <div className="top-sub-menu">
                <div className="container">
                    <ul className="inline">
                        <li><NavLink to="/instruction" className="submenu-item"><i className="flaticon-instruction"></i><span>Instructions</span></NavLink></li>
                        <li><NavLink to="/timeline" className="submenu-item"><i className="flaticon-timeline"></i><span>Contest Timelines</span></NavLink></li>
                        <li><NavLink to="/evaluation-criteria" className="submenu-item"><i className="flaticon-evaluation"></i><span>Evaluation Criteria</span></NavLink></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default TopSecSubMenuComponent;