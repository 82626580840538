module.exports = {
    ENV: {
        // baseUrl: 'https://mathmania.mathlete.pk/',
        // assetUrl: 'https://mathmania.mathlete.pk',
        // url: 'https://mathmania.mathlete.pk/v1/',
        
        // baseUrl: 'https://test.mathlete.pk/',
        // assetUrl: 'https://test.mathlete.pk',
        // url: 'https://test.mathlete.pk/v1/',

        // baseUrl: 'https://test.mathlete.pk/',
        // assetUrl: 'https://test.mathlete.pk',
        // url: 'https://test.mathlete.pk/v1/',

        // baseUrl: 'http://192.168.99.50:3000/',
        // assetUrl: 'http://192.168.99.50:3000',
        // url: 'http://192.168.99.50:3000/v1/',


        baseUrl: 'https://mathmania.arhamsoft.org/',
        assetUrl: 'https://mathmania.arhamsoft.org',
        url: 'https://mathmania.arhamsoft.org/v1/',
        

        // Headers
        Authorization: 'Bearer U2FsdGVkX19N17nCvW63G3X1nBf6ixXpyzfSyArEw/YCEAbEYSLiaFaQN4p9Sbr3BH8fhcDBZQvhccdKLpCFcB1BfDnzVKe3WfX6bgWRiik=',
        x_auth_token: 'F8KLhlTVO8psuIB',

        clearStorage: function () {
            localStorage.removeItem('token-site');
            localStorage.removeItem('userID-site');
            localStorage.removeItem('userGrade-site');
        },

        // strong password regex
        // new RegExp("^[a-zA-Z0-9]{8,}")
        strongPassword: new RegExp('^.{8,}$'),
        strongPasswordMsg: 'Password must be of at least 8 characters.',

        // cdn base url
        cdnBaseUrl: 'https://mathletepk.s3.us-west-2.amazonaws.com'
    }
}
