// AUTH
export const LOGIN_ACTION = 'LOGIN_ACTION';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const API_CALL_CHECK = 'API_CALL_CHECK';
export const RECOVERY_EMAIL = 'RECOVERY_EMAIL';
export const BEFORE_LOGIN = 'BEFORE_LOGIN';
export const REGISTER_USER = 'REGISTER_USER';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const BEFORE_CLEAR_SESSION = 'BEFORE_CLEAR_SESSION';
export const APP_URL = 'APP_URL';
export const BEFORE_APP = 'BEFORE_APP';
export const CREATE_MATHLETE_ACCOUNT = 'CREATE_MATHLETE_ACCOUNT';

// Quiz
export const CREATE_QUIZ = 'CREATE_QUIZ';
export const BEFORE_CREATE_QUIZ = 'BEFORE_CREATE_QUIZ';
export const SEEN_QUESTION = 'SEEN_QUESTION';
export const MARK_ANSWER = 'MARK_ANSWER';
export const GET_QUIZ_ATTEMPT = 'GET_QUIZ_ATTEMPT';
export const MARK_SEEN_QA = 'MARK_SEEN_QA';
export const CHECK_PHASE = 'CHECK_PHASE';
export const CODE_POPUP_SEEN = 'CODE_POPUP_SEEN';
export const QUIT_QUIZ = 'QUIT_QUIZ';
export const QUESTION_REPORT_PROBLEM = 'QUESTION_REPORT_PROBLEM';

// Quiz Results
export const BEFORE_QUIZ_RESULTS = 'BEFORE_QUIZ_RESULTS';
export const QUIZ_RESULTS = 'QUIZ_RESULTS';
export const DOWNLOAD_QUIZ_RESULT = 'DOWNLOAD_QUIZ_RESULT';
export const BEFORE_DOWNLOAD_QUIZ_RESULT = 'BEFORE_DOWNLOAD_QUIZ_RESULT';

//Deals
export const GET_DEALS = 'GET_DEALS';
export const BEFORE_DEALS = 'BEFORE_DEALS';
export const GET_DEALS_SLIDERS = 'GET_DEALS_SLIDERS';
export const GET_DEALS_DETAILS = 'GET_DEALS_DETAILS';
export const REDEEME_DEAL = 'REDEEME_DEAL';

//Campaigns
export const BEFORE_CAMPAIGNS = 'BEFORE_CAMPAIGNS';
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const GET_CAMPAIGN_DETAILS = 'GET_CAMPAIGN_DETAILS';

//Dashboard
export const GET_PRACTICE_STATS = 'GET_PRACTICE_STATS';
export const BEFORE_STATS = 'BEFORE_STATS';
export const GET_DETAILED_STATS = 'GET_DETAILED_STATS';
export const EXPORT_DETAILED_STATS = 'EXPORT_DETAILED_STATS';

//Contest
export const CREATE_CONTEST_CODE = 'CREATE_CONTEST_CODE';
export const BEFORE_CONTEST_CODE = 'BEFORE_CONTEST_CODE';

// STUDENT PROFILE
export const GET_STUDENT_PROFILE = 'GET_STUDENT_PROFILE';
export const UPDATE_STUDENT_PROFILE = 'UPDATE_STUDENT_PROFILE';
export const UPDATE_STUDENT_PASSWORD = 'UPDATE_STUDENT_PASSWORD';
export const BEFORE_STUDENT_PROFILE = 'BEFORE_STUDENT_PROFILE';
export const IDENTITY_DOCUMENTS = 'IDENTITY_DOCUMENTS';
export const RECEIPT_DOCUMENTS = 'RECEIPT_DOCUMENTS';

// SHARED 
export const SEND_EMAIL_RES = 'SEND_EMAIL_RES';
export const EMAIL_VERIFIED = 'EMAIL_VERIFIED';
export const BEFORE_EMAIL = 'BEFORE_EMAIL';

// SUPPORT CENTER
export const GET_FAQS = 'GET_FAQS';

// QUERY
export const BEFORE_QUERY = 'BEFORE_QUERY';
export const ADD_QUERY = 'ADD_QUERY';

// QUERY
export const BEFORE_SITE_SETTINGS = 'BEFORE_SITE_SETTINGS';
export const GET_SITE_SETTINGS = 'GET_SITE_SETTINGS';

// ERRORS
export const GET_ERRORS = 'GET_ERRORS';
export const EMPTY_ERRORS = 'EMPTY_ERRORS';

// HOME 
export const GET_ALL_SLIDERS = 'GET_ALL_SLIDERS';

// Header
export const BEFORE_HEADER_CALL = 'BEFORE_HEADER_CALL';
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';
export const UPDATE_USER_NOTIFICATION = 'UPDATE_USER_NOTIFICATION';
export const INSTRUCTIONS_POPUP_SEEN = 'UPDATE_USER_NOTIFICATION';

// TOP MATHLETES
export const GET_TOP_MATHLETES = 'GET_TOP_MATHLETES';
export const GET_FINAL_TOP_PERFORMERS = 'GET_FINAL_TOP_PERFORMERS';
export const BEFORE_TOP_MATHLETES = 'BEFORE_TOP_MATHLETES';

// TOP PERFORMERS
export const GET_TOP_PERFORMERS = 'GET_TOP_PERFORMERS';
export const BEFORE_TOP_PERFORMERS = 'BEFORE_TOP_PERFORMERS';

// DEAL REDEEM HISTORY
export const GET_DEAL_REDEEM_HISTORY = 'GET_DEAL_REDEEM_HISTORY';
export const BEFORE_DEAL_REDEEM_HISTORY = 'BEFORE_DEAL_REDEEM_HISTORY';